import React, {useCallback} from 'react';
import './App.css';
import {QueryClient, QueryClientProvider, useQuery} from "react-query";
import {BraveBuildContainer} from "./components/BraveBuildContainer";
import {BraveBuild} from "./models/models";

function App() {
    const fetchBravery = () => fetch(
        "https://api.bravery.noobgam.com/testBuildBravery"
    ).then(async r => {
        const res = await r.json()
        console.log(res);
        return res;
    });

    const {data, refetch} = useQuery("my_key", fetchBravery, {
        refetchOnWindowFocus: false,
        enabled: false // disable this query from automatically running
    });
    const handleClick = () => {
        refetch()
    }
    return (
        <div className="App">
            {data && (<BraveBuildContainer build={data as BraveBuild}/>)}
            <button onClick={handleClick}>Regenerate</button>
        </div>
    );
}

export default App;
