import {FC} from "react";
import {BraveBuild} from "../models/models";

export interface BraveBuildContainerProps {
    build: BraveBuild;
}

// https://ddragon.leagueoflegends.com/cdn/img/perk-images/Styles/Resolve/GraspOfTheUndying/GraspOfTheUndying.png
// https://ddragon.leagueoflegends.com/cdn/13.17.1/img/champion/Aatrox.png
const patchCdnPrefix = `https://ddragon.leagueoflegends.com/cdn/13.17.1/img`;
const globalCdnPrefix = `https://ddragon.leagueoflegends.com/cdn/img`;

export const BraveBuildContainer: FC<BraveBuildContainerProps> = ({build}) => {

    return (
        <div>
            <span>{build.name}</span>
            <img src={`${patchCdnPrefix}/champion/${build.champion_choice.champion.image}`}></img>
            {build.champion_choice.summoner_spells.map(sp => (
                <img src={`${patchCdnPrefix}/spell/${sp.image}`}></img>
            ))}
        </div>
    )
}
